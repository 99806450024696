<template>
    <div :style="{'--bg': `url(${loginBg})`}" class="Login">
        <div class="Login__body">
            <div class="Login__body--logo">
                <i class="iconfont icon-logo-18bai"></i>
            </div>
            <div class="Login__body--title">中呼400渠道查号</div>
            <div class="Login__body--form">
                <van-form :submit-on-enter="false" @submit="onSubmit">
                    <van-field v-model="login.loginName"
                               placeholder="请输入账号"
                               size="large"
                    />
                    <van-field v-model="login.password"
                               :type="type"
                               placeholder="请输入登录密码"
                               size="large"
                    >
                        <i slot="right-icon" :class="['iconfont', type === 'password' ? 'icon-xianshi' : 'icon-ai47']" @click="changeType"></i>
                    </van-field>
                    <div style="margin: 16px;">
                        <van-button :disabled="!login.loginName || !login.password" block native-type="submit" type="info">登录</van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>

<script>
    import loginBg from "@/assets/login.png";
    import {mapActions, mapState} from "vuex";

    export default {
        name: "Login",
        data() {
            return {
                login: {
                    loginName: '',
                    password: '',
                },
                loginBg,
                type: 'password'
            };
        },
        computed: {
            ...mapState('user', ['openid'])
        },
        methods: {
            ...mapActions('user', ['toLogin']),
            onSubmit() {
                // this.$toast('账号或密码验证失败，请确认后再次尝试');
                console.log('submit', this.login, this.openid);
                this.toLogin(this.login).then(res => {
                    window.console.log('login', res);
                    this.$router.push("/");
                });
            },
            changeType() {
                this.type = this.type === 'password' ? 'text' : 'password';
            }
        },
    }
</script>

<style lang="scss" scoped>
    .Login {
        height: 100vh;
        background: #4D88FD var(--bg) no-repeat;
        background-size: contain;
        background-position: bottom;
        &__body {
            position: relative;
            top: 10%;
            &--logo {
                height: 120px;
                width: 120px;
                text-align: center;
                margin: auto;
                display: table;
                .icon-logo-18bai {
                    font-size: 60px;
                    vertical-align: middle;
                    display: table-cell;
                    color: #FFFFFF;
                }
            }
            &--title {
                font-size: 24px;
                color: #FFFFFF;
                font-family: SourceHanSansSC-regular;
                padding-bottom: 34px;
                text-align: center;
            }
            &--form {
                margin: 16px;
                padding: 14px 4px 14px 4px;
                background: #FFFFFF;
                border-radius: 6px;
                border: 1px solid #FFFFFF;
                .iconfont {
                    font-size: 20px;
                    display: block;
                    opacity: 0.6;
                }
            }
        }
    }
</style>